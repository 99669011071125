<template>
  <div class="container">
    <div class="section-one">
      <iframe src="http://sg.mikecrm.com/ruy0nKP" frameborder="0" scrolling="Yes" width="100%" height="100%" noresize="noresize"></iframe>
      <!-- <script type="application/javascript" defer src="http://www.wenjuan.com/iframe/6013b9513631f2840fff841e/?params=hide_mobile_icon=true"></script> -->
    </div>         
  </div>
</template>
<script>
export default {
}
</script>
<style lang="sass" scoped>
  .section-one
    position: relative
    width: 100%
    height: 0
    padding-bottom: 100%
    iframe
      overflow: hidden
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
</style>